<template>
  <div class="header">
    <div class="logo">
      <a href="index.html">
        <img src="../assets/logo-with-txt-02.png" alt="" />
      </a>
    </div>
    <div class="menu">
      <a href="#"><img src="../assets/menu.png" alt="" /></a>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'HeaderComponent',
  mounted() {
    $(".header .menu a").click(function () {
      $(".menuBg").fadeIn();
      $(".menuBox").toggleClass("show");
      return false;
    });
    $(".menuBox .close a,.menuBg").click(function () {
      $(".menuBg").fadeOut();
      $(".menuBox").removeClass("show");
      return false;
    });
  }
};
</script>

<style scoped>
/* Copy header CSS here */
.header{
  padding: 0.31rem 0 0.28rem;
  position: sticky;
    top: 0;
    z-index: 0;    background: #000;

    .logo{
      margin: 0 auto;
      width: 2.68rem;

      img{
    vertical-align: top;
    width: 100%;
  }

    }

    .menu{
      display: none;
      width: 0.56rem;
    position: absolute;
    left: 0.54rem;
    /* top: 0.44rem; */
    top: 0.65rem;

    img{
      vertical-align: top;
      width: 100%;
    }
    }
}
</style>